import { atom } from "nanostores";
import {persistentAtom} from "@nanostores/persistent"

export const themeChoices = [
    // default theme
    "dark",
    "slate",
    "light",
    "emerald",
    "pink"
  ]

export const currThemeIdx = atom<string>("0");

// document.documentElement.dataset.theme = themeChoices[parseInt(currThemeIdx.get())]