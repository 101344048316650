import { useEffect, useLayoutEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { atom } from "nanostores";
import { useStore } from "@nanostores/react";
import { isMobileMenuOpen } from "src/context/menuStore";
import { navigation } from "@context/server";
import { currThemeIdx, themeChoices } from "src/context/theme";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { cMerge } from "@utils/cMerge";

export function HeaderMenuButton() {
    // read the store value with the `useStore` hook
    const _isMenuOpen = useStore(isMobileMenuOpen);
    // write to the imported store using `.set`
    return (
        <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 pointer-events-auto"
            onClick={() => isMobileMenuOpen.set(!_isMenuOpen)}
        >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
    );
}

export const ThemeSwitcher = () => {
    useLayoutEffect(() => {
        document.documentElement.dataset.theme =
            themeChoices[parseInt(currThemeIdx.get())];
    }, []);
    return (
        <button
            onClick={() => {
                currThemeIdx.set(
                    (
                        (parseInt(currThemeIdx.get()) + 1) %
                        themeChoices.length
                    ).toString()
                );
                document.documentElement.dataset.theme =
                    themeChoices[parseInt(currThemeIdx.get())];
            }}
            className="theme-switcher | pointer-events-auto block rounded border border-solid uppercase switcher"
            aria-label="Switch color theme"
        ></button>
    );
};

export const NavButtons = () => {

  const navLink = (itemName: string, className?: string, ) => {
    return (
              <div className={cMerge("nav-link-inner", className)}>
                  <span style={{gridArea: "i"}}
                      className="block h-1 w-1 bg-current"
                  ></span>
                  <span style={{gridArea: "s"}} className="whitespace-nowrap">{itemName}</span>
              </div>      
    )
  }

    return navigation.map((item, idx) => (
        <a
            href={item.href}
            key={idx}
            className="nav-link text-body-3 pointer-events-auto"
        >
            {navLink(item.name)}
            {navLink(item.name, "link-hover")}


            <span
                className="nav-link-bg | absolute z-0 block h-full w-full origin-left scale-x-0 bg-current transition-transform duration-300"
            ></span>
        </a>
    ));
};
